import api from "../utils/api";

export function putMaintenanceStatus(
  payload: any,
  cbs?: (data: any) => void,
  cbe?: (error: any) => void
) {
  api
    .put(`/admin/system`, payload)
    .then((response) => {
      if (cbs) cbs(response.data);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}


export function getMaintenanceStatus(
  cbs?: (data: any) => void,
  cbe?: (error: any) => void
) {
  api
    .get("/admin/system")
    .then((response) => {
      if (cbs) cbs(response.data.payload);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}