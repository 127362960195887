import api from "../utils/api";

export function getTasks(
  cbs?: (data: any) => void,
  cbe?: (error: any) => void
) {
  api
    .get("/admin/tasks", { withCredentials: true })
    .then((response) => {
      if (cbs) cbs(response.data.payload);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}

export function postTaskStatus(
  payload: any,
  cbs?: (data: any) => void,
  cbe?: (error: any) => void
) {
  api
    .post(`/admin/tasks`, payload)
    .then((response) => {
      if (cbs) cbs(response.data);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}

export function putTaskStatus(
  id: string,
  payload: any,
  cbs?: (data: any) => void,
  cbe?: (error: any) => void
) {
  api
    .put(`/admin/tasks/${id}`, payload)
    .then((response) => {
      if (cbs) cbs(response.data);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}

export function deleteTask(
  id: string,
  cbs?: (data: any) => void,
  cbe?: (error: any) => void
) {
  api
    .delete(`/admin/tasks/${id}`)
    .then((response) => {
      if (cbs) cbs(response.data);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}
