import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Modal, Select, Skeleton } from "antd";
import type { TableProps } from "antd";
import styled from "styled-components";
import { useAuth } from "../../context/AuthContext";
import { formatAddress } from "../../utils/formatAddress";
import { updateTransactionStatus } from "../../api/transactions";
import toast from "react-hot-toast";
import { getTransaction } from "../../api/transactions";
import { Transaction } from "../../types/transactions";

const Wrapper = styled.div`
  color: #000;
  padding: 1rem;

  .container {
    margin: 2rem auto 4rem;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .table-container {
    overflow-x: auto;
  }

  .ant-table {
    /* max-height: 500px; */
  }

  @media (max-width: 768px) {
    .ant-table {
      min-width: 1000px; /* Set a fixed width for the table */
    }
  }
`;

enum Status {
  DONE = "DONE",
  SUCCESS = "SUCCESS",
  WAITING = "WAITING",
  PROCESSING = "PROCESSING",
  ERROR = "ERROR",
}

const getStatusColor = (status: string) => {
  switch (status) {
    case Status.DONE:
      return "green";
    case Status.SUCCESS:
      return "green";
    case Status.WAITING:
      return "geekblue";
    case Status.PROCESSING:
      return "orange";
    case Status.ERROR:
      return "volcano";
    default:
      return "gray";
  }
};

const TransactionsPage: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Transaction | null>(
    null
  );
  const [newStatus, setNewStatus] = useState<string>("");
  const [data, setData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const showModal = (record: Transaction) => {
    setNewStatus(record.status);
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedRecord) {
      selectedRecord.status = newStatus;
      updateTransactionStatus(
        selectedRecord._id,
        { status: newStatus },
        (resp) => {
          toast.success("Status updated successfully");
          // update status on data not call api again
          setData(
            data.map((item) => {
              if (item._id === selectedRecord._id) {
                return {
                  ...item,
                  status: newStatus,
                };
              }
              return item;
            })
          );
        },
        (error) => {}
      );

      setIsModalVisible(false);
      setSelectedRecord(null);
      setNewStatus("");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
    setNewStatus("");
  };

  const columns: TableProps<Transaction>["columns"] = [
    {
      title: "Tx Hash",
      dataIndex: "txHash",
      key: "txHash",
      render: (txHash) => <div>{formatAddress(txHash)}</div>,
    },
    {
      title: "LT",
      dataIndex: "lt",
      key: "lt",
      render: (lt) => <div>{formatAddress(lt)}</div>,
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (from) => <div>{formatAddress(from)}</div>,
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (to) => <div>{formatAddress(to)}</div>,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      sorter: (a, b) => a.value - b.value,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: Object.values(Status).map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.status.indexOf(value as string) === 0,
      render: (_, { status }) => (
        <>
          <Tag color={getStatusColor(status)} key={status}>
            {status.toUpperCase()}
          </Tag>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => showModal(record)}>Update Status</a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    getTransaction(
      (resp: any) => {
        setData(
          resp?.payload?.data.map((item: Transaction) => ({
            ...item,
            key: item._id,
          }))
        );
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <Wrapper>
      <div className="container">
        <h1>Transactions</h1>

        <div className="table-container">
          {loading ? (
            <Skeleton active />
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ y: 500 }}
              locale={{ emptyText: "No data available" }}
            />
          )}
        </div>
        <Modal
          title="Update Status"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Select
            style={{ width: "100%" }}
            value={newStatus}
            onChange={(value) => setNewStatus(value)}
          >
            {Object.values(Status).map((status) => (
              <Select.Option
                key={status}
                value={status}
                style={{ color: getStatusColor(status) }}
              >
                {status}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      </div>
    </Wrapper>
  );
};

export default TransactionsPage;
