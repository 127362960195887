import api from "../utils/api";

export function getTransaction(cbs?: (data: any) => void, cbe?: (error: any) => void) {
  api.get('/admin/trans')
    .then((response) => {
      if (cbs) cbs(response.data);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}

export function updateTransactionStatus(id: string, payload: any, cbs?: (data: any) => void, cbe?: (error: any) => void) {
  api.put(`/admin/trans/${id}`, payload)
    .then((response) => {
      if (cbs) cbs(response.data);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}

