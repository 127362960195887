export enum ETaskType {
  WEB = "WEB",
  TELEGRAM = "TELEGRAM",
  X = "X",
  OTHERS = "OTHERS",
}

export type Task = {
  _id: string;
  name: string;
  link: string;
  reward: string;
  isActive: boolean;
  type: ETaskType;
  __v: number;
  createdAt: string;
  updatedAt: string;
};