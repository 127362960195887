import React from "react";
import styled from "styled-components";
import { Layout, Menu } from "antd";
import { FileDoneOutlined, TransactionOutlined } from "@ant-design/icons";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import MaintenanceToggle from "../MaintenanceToggle";

const { Sider } = Layout;

const ResponsiveSider = styled(Sider)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const LayoutWithPadding = styled(Layout)`
  padding: 0 24px 24px;

  @media (max-width: 768px) {
    padding: 0 0 24px;
  }
`;

const ResponsiveMenu = styled(Menu)`
  display: none;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    padding: 0 8px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-size: 12px; /* Adjust the font size as needed */
`;

const StyledContent = styled.div`
  margin: 0;
  height: 100%;
  min-height: 280px;
  background: #fff;
`;

const RootLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (e: any) => {
    navigate(e.key);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <Layout>
        <ResponsiveSider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{ height: "100%", borderRight: 0 }}
            onClick={handleMenuClick}
          >
            <Menu.Item key="/transactions" icon={<TransactionOutlined />}>
              <NavLink to="/transactions">Transactions</NavLink>
            </Menu.Item>
            <Menu.Item key="/tasks" icon={<FileDoneOutlined />}>
              <NavLink to="/tasks">Tasks</NavLink>
            </Menu.Item>
            <MaintenanceToggle />
          </Menu>
        </ResponsiveSider>
        <LayoutWithPadding style={{ height: "calc(100vh - 64px)" }}>
          <ResponsiveMenu
            mode="horizontal"
            selectedKeys={[location.pathname]}
            onClick={handleMenuClick}
          >
            <FlexContainer>
              <div>
                <NavLink to="/transactions">
                  <Menu.Item key="/transactions" icon={<TransactionOutlined />}>
                    Transactions
                  </Menu.Item>
                </NavLink>
                <NavLink to="/tasks">
                  <Menu.Item key="/tasks" icon={<FileDoneOutlined />}>
                    Tasks
                  </Menu.Item>
                </NavLink>
              </div>

              <div>
                <MaintenanceToggle />
              </div>
            </FlexContainer>
          </ResponsiveMenu>
          <StyledContent>{children}</StyledContent>
        </LayoutWithPadding>
      </Layout>
    </Layout>
  );
};

export default RootLayout;
