import React, { useState } from "react";
import { Switch, Spin, Space } from "antd";
import { putMaintenanceStatus } from "../../api/system";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { updateSystemStatus } from "../../store/system";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 10px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const MaintenanceToggle: React.FC = () => {
  const isMaintenance = useSelector((state: RootState) => state.system.status);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const toggleMaintenance = () => {
    if (loading) return;

    setLoading(true);
    dispatch(updateSystemStatus(!isMaintenance));

    putMaintenanceStatus(
      { status: !isMaintenance },
      (data) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  return (
    <Wrapper>
      <span>Maintenance Mode: </span>

      <Space size="middle">
        <Spin spinning={loading}>
          <Switch
            style={{ marginLeft: "5px" }}
            checked={isMaintenance}
            onChange={toggleMaintenance}
            disabled={loading}
          />
        </Spin>
      </Space>
    </Wrapper>
  );
};

export default React.memo(MaintenanceToggle);
