import axios from 'axios';
import { logout } from './authUtils';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // If the error is due to an expired token (assuming your server returns 401 for expired tokens)
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      logout();
    }

    return Promise.reject(error);
  }
);


export default api;