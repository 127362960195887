import { useEffect } from 'react';
import { getMaintenanceStatus } from '../api/system';
import { useDispatch } from 'react-redux';
import { updateSystemStatus } from '../store/system';
import { useAuth } from '../context/AuthContext';

// Hook to check if the app is in maintenance mode
const useMaintenanceMode = () => {
  const dispatch = useDispatch();
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      getMaintenanceStatus((data) => {
        dispatch(updateSystemStatus(data?.data[0].isMaintain))
      });
    }

  }, [token]);

  return null;
};

export default useMaintenanceMode;