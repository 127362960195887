import React, { useCallback, useEffect, useState } from "react";
import {
  Space,
  Table,
  Tag,
  Modal,
  Switch,
  Skeleton,
  Form,
  Button,
  Input,
  InputNumber,
  Select,
} from "antd";

import type { TableProps } from "antd";
import styled from "styled-components";
import {
  getTasks,
  putTaskStatus,
  deleteTask,
  postTaskStatus,
} from "../../api/tasks";
import { ETaskType, Task } from "../../types";
import toast from "react-hot-toast";
const { Option } = Select;

const Wrapper = styled.div`
  color: #000;
  padding: 1rem;
  .container {
    margin: 2rem auto 4rem;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .table-container {
    margin-top: 1rem;
    overflow-x: auto;
  }

  .ant-table {
    /* max-height: 500px; */
  }

  @media (max-width: 768px) {
    .ant-table {
      min-width: 900px; /* Set a fixed width for the table */
    }
  }
`;

const TasksPage: React.FC = () => {
  const [data, setData] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [isUpdateTaskModalVisible, setIsUpdateTaskModalVisible] =
    useState(false);
  const [isDeleteTaskModalVisible, setIsDeleteTaskModalVisible] =
    useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [taskToDelete, setTaskToDelete] = useState<Task | null>(null);
  const [form] = Form.useForm();

  const showAddTaskModal = () => {
    setIsAddTaskModalVisible(true);
  };

  const refeshTasks = useCallback(() => {
    getTasks(
      (resp: any) => {
        setData(resp?.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const showUpdateTaskModal = (task: Task) => {
    setSelectedTask(task);
    form.setFieldsValue(task);
    setIsUpdateTaskModalVisible(true);
  };

  const showDeleteTaskModal = (task: Task) => {
    setTaskToDelete(task);
    setIsDeleteTaskModalVisible(true);
  };

  const handleAddTaskOk = () => {
    form
      .validateFields()
      .then((values) => {
        postTaskStatus(
          values,
          (resp: any) => {
            refeshTasks();
            toast.success("Task added successfully");
          },
          (error) => {
            toast.error("Failed to add task");
          }
        );

        setIsAddTaskModalVisible(false);
        form.resetFields();
      })
      .catch((info) => {
        toast.error("Failed to add task");
      });
  };

  const handleUpdateTaskOk = () => {
    form
      .validateFields()
      .then((values) => {
        putTaskStatus(
          selectedTask?._id,
          { ...values },
          (resp: any) => {
            refeshTasks();
            toast.success(`Task ${selectedTask?.name} updated successfully`);
          },
          (error) => {
            toast.error("Failed to update task");
          }
        );

        setIsUpdateTaskModalVisible(false);
        setSelectedTask(null);
        form.resetFields();
      })
      .catch((info) => {});
  };

  const handleDeleteTaskOk = () => {
    if (taskToDelete) {
      deleteTask(
        taskToDelete._id,
        (resp: any) => {
          refeshTasks();
          toast.success(`Task ${taskToDelete.name} deleted successfully`);
        },
        (error) => {
          toast.error("Failed to delete task");
        }
      );
    }
    setIsDeleteTaskModalVisible(false);
    setTaskToDelete(null);
  };

  const handleAddTaskCancel = () => {
    setIsAddTaskModalVisible(false);
    form.resetFields();
  };

  const handleUpdateTaskCancel = () => {
    setIsUpdateTaskModalVisible(false);
    setSelectedTask(null);
    form.resetFields();
  };

  const handleDeleteTaskCancel = () => {
    setIsDeleteTaskModalVisible(false);
    setTaskToDelete(null);
  };

  const columns: TableProps<Task>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      width: 300,
      render: (link) => (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      ),
    },
    {
      title: "Reward",
      dataIndex: "reward",
      key: "reward",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive) => (
        <Tag color={isActive ? "green" : "volcano"}>
          {isActive ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => showUpdateTaskModal(record)}>Update</a>
          <a
            onClick={() => showDeleteTaskModal(record)}
            style={{ color: "#d4380d" }}
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    refeshTasks();
  }, []);

  return (
    <Wrapper>
      <div className="container">
        <h1>Tasks</h1>

        <div className="flex justify-end">
          <Button type="primary" onClick={showAddTaskModal}>
            Add New Task
          </Button>
        </div>

        <div className="table-container">
          {loading ? (
            <Skeleton active />
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              rowKey="_id"
              scroll={{ y: 500 }}
              locale={{ emptyText: "No data available" }}
            />
          )}
        </div>

        <Modal
          title="Add New Task"
          visible={isAddTaskModalVisible}
          onOk={handleAddTaskOk}
          onCancel={handleAddTaskCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="Task Name"
              rules={[
                { required: true, message: "Please input the task name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="link"
              label="Link"
              rules={[{ required: true, message: "Please input the link!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="reward"
              label="Reward"
              rules={[{ required: true, message: "Please input the reward!" }]}
            >
              <InputNumber min={1} className="" />
            </Form.Item>
            <Form.Item
              name="type"
              label="Type"
              initialValue={ETaskType.WEB}
              rules={[{ required: true, message: "Please select the type!" }]}
            >
              <Select defaultValue={ETaskType.WEB}>
                {Object.values(ETaskType).map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Update Task"
          visible={isUpdateTaskModalVisible}
          onOk={handleUpdateTaskOk}
          onCancel={handleUpdateTaskCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="isActive" label="Status" valuePropName="checked">
              <Switch checkedChildren={true} unCheckedChildren={false} />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Delete Task"
          visible={isDeleteTaskModalVisible}
          onOk={handleDeleteTaskOk}
          onCancel={handleDeleteTaskCancel}
        >
          <p>
            Are you sure you want to delete <b>{taskToDelete?.name}</b> task?
          </p>
        </Modal>
      </div>
    </Wrapper>
  );
};

export default TasksPage;
