import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import TransactionsPage from "./pages/Transactions";
import RootLayout from "./components/Layout/RootLayout";
import { AuthProvider } from "./context/AuthContext";
import LoginForm from "./components/Auth/LoginForm";
import ProtectedRoute from "./components/ProtectedRoute";
import { Toaster } from "react-hot-toast";
import TasksPage from "./pages/Tasks";

function App() {
  return (
    <AuthProvider>
      <Toaster
        toastOptions={{
          duration: 2000,
          error: {
            style: {
              backgroundColor: "rgba(255, 58, 94, 1)",
              color: "white",
            },
          },
          success: {
            style: {
              backgroundColor: "rgba(1, 242, 141, 1)",
              color: "white",
            },
          },
        }}
        containerStyle={{
          zIndex: 19999999,
        }}
      />
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/transactions" />} />
          <Route path="/login" element={<LoginForm />} />
          <Route
            path="/transactions"
            element={
              <ProtectedRoute>
                <RootLayout>
                  <TransactionsPage />
                </RootLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/tasks"
            element={
              <ProtectedRoute>
                <RootLayout>
                  <TasksPage />
                </RootLayout>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
