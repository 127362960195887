import { AuthContextType } from "../context/AuthContext";

let authContext: AuthContextType | null = null;

export const setAuthContext = (context: AuthContextType) => {
  authContext = context;
};

export const logout = () => {
  if (authContext) {
    authContext.logout();
  }
};

export const getToken = () => {
  if (authContext) {
    return authContext.token;
  }
  return null;
}