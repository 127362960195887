import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
  status: boolean
}

const initialState: CounterState = {
  status: false,
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    updateSystemStatus: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateSystemStatus } = systemSlice.actions

export default systemSlice.reducer