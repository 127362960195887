import api from "../utils/api";

export function postLogin(payload: any, cbs?: (data: any) => void, cbe?: (error: any) => void) {
  api.post('/login', payload)
    .then((response) => {
      if (cbs) cbs(response.data);
    })
    .catch((error) => {
      if (cbe) cbe(error);
    });
}