import React, { useState } from "react";
import styled from "styled-components";
import { Layout, Menu, Avatar, Dropdown } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../../context/AuthContext";

const { Header: AntHeader } = Layout;

const StyledHeader = styled.div`
  :where(.css-dev-only-do-not-override-1gwfwyx).ant-layout-header {
    padding: 0 !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 24px;
  .logo {
    display: flex;
    align-items: center;

    div {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .header-right {
  }
`;

const Header: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { logout, token } = useAuth();

  const handleLogout = () => {
    setIsLoggedIn(false);
    logout();
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledHeader>
      <AntHeader>
        {/* <div className="container"> */}
        <Wrapper>
          <div className="logo">
            <img src="/images/logo.png" width={60} height={60} alt="" />
            <div>Tondef</div>
          </div>
          <div className="header-right">
            <Dropdown overlay={userMenu} placement="bottomRight">
              <div>
                <Avatar icon={<UserOutlined />} />
                <span style={{ marginLeft: 8 }}>{token}</span>
              </div>
            </Dropdown>
          </div>
        </Wrapper>
        {/* </div> */}
      </AntHeader>
    </StyledHeader>
  );
};

export default Header;
